<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";
import { getLocationDetails, patchLocationDetails, postLocationDetails, severityCode } from "@/services/api/location.api";
import { getEedTypes, getLocationTypes, getNta8800Functions, getEcmFunctions, getWeiiBldCategories } from "@/services/api/masterdata.api";
import { ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import useDetailEdit from "@/composables/detailEdit.js";
import router from "@/router/index";
import { navigateToLocation } from "@/composables/general.js";
import _ from "lodash";
import { emptyGuid } from "@/composables/miscellaneous.js";
import DateInput from "@/components/general/DateInput.vue";

const {
  setMode,
  setReadonlyMode,
  setEditMode,
  setEditTLMode,
  setDetails,
  hasDetails,
  selectNewIndex,
  selectedData,
  updateSelectedDetail,
  resetSelectedDetail,
  isReadOnly,
  isAddingMode,
  isAddingMasterMode,
  isAddingDetailMode,
  isEditTLMode,
  allowEdit,
  allowEditTL,
  addNewDetailMode,
  detailTabs,
  modDateFormatted,
  checkDateValid,
} = useDetailEdit();

setInteractionMode("eager");

const props = defineProps({
  locationReference: String,
  addModus: {
    type: Boolean,
    default: false,
  },
  calledFromLocationDetail: {
    type: Boolean,
    default: false,
  },
});

const locationTypes = ref([]);
const eedTypes = ref([]);
const nta8800Functions = ref([]);
const weiiBldDecrees = ref([]);
const allWeiiBldCategories = ref([]);
const weiiBldCategories = ref([]);
const ecmFunctions = ref([]);

const selectedTabIndex = ref(undefined);
const dataMustBeRetrieved = ref(false);

const locationTypesLoaded = ref(false);
const eedTypesLoaded = ref(false);
const nta8800FunctionsLoaded = ref(false);
const categoriesLoaded = ref(false);
const ecmFunctionsLoaded = ref(false);
const isSaving = ref(false);

const masterDataLoaded = computed(() => categoriesLoaded.value && eedTypesLoaded.value && locationTypesLoaded.value && nta8800FunctionsLoaded.value && ecmFunctionsLoaded.value);

watch(
  () => [props.locationReference, props.addModus],
  () => {
    setMode(props.locationReference, props.addModus);
    if (!isAddingMasterMode.value) {
      dataMustBeRetrieved.value = true;
      fetchData();
    }
  },
  { immediate: true }
);

watch(
  () => masterDataLoaded.value,
  () => {
    if (!isAddingMode.value) {
      fetchData();
    }
  }
);

watch(
  () => selectedTabIndex.value,
  () => {
    selectNewIndex(selectedTabIndex.value);
  }
);

watch(
  () => selectedData.value.weiiBldDecreeKey,
    () => {
    filterWeiiBldCategories(selectedData.value.weiiBldDecreeKey);

    if (allowEdit.value){
      if (selectedData.value.weiiBldDecreeKey === null) {
        selectedData.value.weiiBldCatKey = null;
      }
      else{
        selectedData.value.weiiBldCatKey = null;
      }
    }
  }
);

onMounted(() => {
  loadWeiiBldDecreesAndCategories();
  loadEedTypes();
  loadLocationTypes();
  loadNta8800Functions();
  loadEcmFunctions();
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function fetchData() {
  if (dataMustBeRetrieved.value && masterDataLoaded.value) {
    const response = await getLocationDetails(props.locationReference);

    if (response && !response.error) {
      selectedTabIndex.value = setDetails(response.data);
      console.log("LocationDetails, fetchData response", detailTabs());
    } else {
      proxy.$toaster.error("Ophalen van de data is mislukt! " + response.error);
    }
    dataMustBeRetrieved.value = false;
  }
}

const obs = ref(null);

async function saveDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    if (isEditTLMode.value) {
      data = { valFrom: selectedData.value.valFrom, valTill: selectedData.value.valTill, modDescr: selectedData.value.modDescr };
    } else {
      data = JSON.parse(JSON.stringify(selectedData.value));
      //datums mogen niet geupdate worden
      data.valFrom = null;
      data.valTill = null;
    }
    console.log("LocationDetails, saveDetail, json: ", isEditTLMode.value, JSON.stringify(selectedData.value));
    console.log("LocationDetails, saveDetail, data: ", selectedData.value, data);
    const result = await patchLocationDetails(selectedData.value.id, data);
    if (!result.error) {
      updateSelectedDetail();
      if (isEditTLMode.value) {
        // refresh de data i.v.m. gewijzigde tijdlijn.
        fetchData();
      }
      setReadonlyMode();
      proxy.$toaster.success("Gegevens van deze locatie zijn successvol gewijzigd.");
    } else {
      proxy.$toaster.error(result.error);
    }
  }
  isSaving.value = false;
}

function addNewDetail() {
  if (selectedData.value) {
    addNewDetailMode();
  } else {
    proxy.$toaster.warning("Selecteer eerst een versie s.v.p..");
  }
}

async function saveNewDetail() {
  isSaving.value = true;
  const valid = await obs.value.validate();
  if (valid) {
    let data = {};
    data = JSON.parse(JSON.stringify(selectedData.value));
    const response = await postLocationDetails(data);
    console.log("LocationDetails, saveNewDetail ", props.locationReference, response);
    switch (response.severity) {
      case severityCode.hint:
      case severityCode.warning:
        proxy.$toaster.warning(response.message);
        break;
      case severityCode.error:
        if (response.message == "conflict") {
          proxy.$toaster.error("Er is een conflict op de tijdlijn!");
        } else {
          proxy.$toaster.error("Bewaren van gegevens is mislukt! " + response.message);
        }
        break;
      default:
        proxy.$toaster.success("Gegevens van deze locatie zijn opgeslagen.");
        break;
    }
    if (response.severity != severityCode.error) {
      isSaving.value = false;
      navigateToLocation(response.data.reference.toString());
    }
  }
  isSaving.value = false;
}

async function onCancelAdd() {
  console.log("LocationDetails, onCancelAdd ");
  if (isAddingMasterMode.value || props.calledFromLocationDetail) {
    // Nieuwe locatie
    router.push({ name: "Locations" });
    proxy.$toaster.warning(`Locatie toevoegen geannuleerd.`);
  } else {
    setReadonlyMode();
    resetSelectedDetail();
    proxy.$toaster.warning(`Locatieversie toevoegen geannuleerd.`);
  }
}

async function onCancel() {
  proxy.$toaster.warning(`bewerken geannuleerd.`);
  selectNewIndex(selectedTabIndex.value);
  requestAnimationFrame(() => {
    obs.value.reset();
  });
  setReadonlyMode();
}

async function loadLocationTypes() {
  const response = await getLocationTypes();
  if (response && !response.error) {
    locationTypes.value = _.sortBy([...response.data.keyDescList], "description");
    locationTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de location types is mislukt! " + response.error);
  }
}

async function loadEedTypes() {
  const response = await getEedTypes();
  if (response && !response.error) {
    eedTypes.value = _.sortBy([...response.data.keyDescList], "description");
    eedTypesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de eed types is mislukt! " + response.error);
  }
}

async function loadNta8800Functions() {
  const response = await getNta8800Functions();
  if (response && !response.error) {
    nta8800Functions.value = _.sortBy([...response.data.nta8800Functions], "descr");
    nta8800Functions.value.unshift({ id: emptyGuid, name: "Geen", descr: "Geen" });
    nta8800FunctionsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de nta8800 functies is mislukt! " + response.error);
  }
}

async function loadEcmFunctions() {
  const response = await getEcmFunctions();
  if (response && !response.error) {
    ecmFunctions.value = _.sortBy([...response.data.ecmFunctions], "name");
    ecmFunctions.value.unshift({ id: emptyGuid, code: "Geen", name: "Geen", descr: "Geen" });
    ecmFunctionsLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de ecm functies is mislukt! " + response.error);
  }
}

async function loadWeiiBldDecreesAndCategories() {
  const response = await getWeiiBldCategories();
  if (response && !response.error) {
    allWeiiBldCategories.value = _.sortBy([...response.data.weiiBldCategories], "name");
    allWeiiBldCategories.value.unshift({ key: null, weiiBldDecreeKey: null, weiiBldDecreeName: "Geen", name: "Geen" });
    weiiBldCategories.value = allWeiiBldCategories.value;
    let uniqueBldDecrees = [...new Map(allWeiiBldCategories.value.map((item) => [item["weiiBldDecreeName"], item])).values()];
    weiiBldDecrees.value = _.sortBy(
      [
        ...uniqueBldDecrees.map(function (item) {
          return { key: item.weiiBldDecreeKey, name: item.weiiBldDecreeName };
        }),
      ],
      "name"
    );
    categoriesLoaded.value = true;
  } else {
    proxy.$toaster.error("Ophalen van de WeiiBldCategories is mislukt! " + response.error);
  }
}

async function filterWeiiBldCategories(key) {
  weiiBldCategories.value = _.filter(allWeiiBldCategories.value, function (categories) {
    return categories.weiiBldDecreeKey == key;
  });
}
</script>

<template>
<v-container fluid pa-0>
    <header class="app-welcome-message">
      <h2 v-if="!isAddingMasterMode && !isAddingDetailMode">Details van locatie</h2>
      <h2 v-if="isAddingMasterMode">Toevoegen van locatie</h2>
      <h2 v-if="isAddingDetailMode">Toevoegen van locatieversie</h2>
    </header>
    <v-row>
      <v-col>
        <v-container fluid>
          <v-row align="center">
            <v-col v-if="hasDetails" cols="auto"> Tijdlijn: </v-col>
            <v-col>
              <v-btn-toggle v-model="selectedTabIndex" group mandatory color="blue accent-3">
                <v-btn class="v-application elevation-2" v-for="(item, index) in detailTabs()" :key="index" :value="index" :disabled="!isReadOnly"> {{ item }} </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-container>
        <ValidationObserver ref="obs">
          <v-form>
            <masonry :cols="{ default: 2, 1264: 1 }" :gutter="12">
              <v-container class="boxed" fluid>
                <header>Geldigheid</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig vanaf" ref="validFrom" rules="date_between|required" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valFrom"
                        persistent-placeholder
                        label="Geldig vanaf"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                        rules="required"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Geldig tot" :rules="`date_between|date_after:${selectedData.valFrom}`" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        v-model="selectedData.valTill"
                        persistent-placeholder
                        label="Geldig tot"
                        :errors="errors"
                        :class="{ noBorders: !allowEditTL }"
                        :outlined="allowEditTL"
                        :readonly="!allowEditTL"
                      ></date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Aanpassing</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aanpassings omschrijving" rules="required" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        v-model="selectedData.modDescr"
                        :error-messages="errors"
                        :class="{ noBorders: !allowEdit && !allowEditTL }"
                        :outlined="allowEdit || allowEditTL"
                        :readonly="!allowEdit && !allowEditTL"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" persistent-placeholder label="Door" v-model="selectedData.modBy" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="!isAddingMode">
                  <v-col>
                    <v-text-field hide-details="auto" persistent-placeholder label="Datum" v-model="modDateFormatted" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <masonry :cols="{ default: 3, 1264: 2, 768: 1 }" :gutter="12">
              <v-container class="boxed">
                <header>Algemene gegevens</header>
                <v-row v-if="selectedData.reference != 0">
                  <v-col>
                    <v-text-field hide-details="auto" persistent-placeholder label="INNAX-ID" v-model="selectedData.reference" readonly class="noBorders"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Omschrijving" rules="required|max:1000" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Omschrijving"
                        :counter="1000"
                        v-model="selectedData.descr"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bouwdatum" rules="date_between:1500-01-01,2050-12-31" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        :value="selectedData.consDat"
                        @input="selectedData.consDat = $event !== '' ? $event : undefined"
                        persistent-placeholder
                        label="Bouwdatum"
                        :min="new Date(1500, 0, 1)"
                        :errors="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Renovatiedatum" rules="date_between" v-slot="{ errors }" :mode="checkDateValid">
                      <date-input
                        :value="selectedData.renovDat"
                        @input="selectedData.renovDat = $event !== '' ? $event : undefined"
                        persistent-placeholder
                        label="Renovatiedatum"
                        :errors="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                      </date-input>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-model="selectedData.isMonumental" label="Is dit gebouw een monument?" :readonly="isReadOnly" hide-details></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gebruikstatus" rules="max:50" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="Gebruikstatus"
                        :counter="50"
                        v-model="selectedData.usgStat"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="boxed">
                <header>Functies</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Type" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="Type"
                        :items="locationTypes"
                        item-text="description"
                        item-value="key"
                        v-model="selectedData.typ"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-select>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      persistent-placeholder
                      label="Functie"
                      :items="ecmFunctions"
                      item-text="name"
                      item-value="id"
                      v-model="selectedData.ecmFuncId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="WEii Bouwbesluit" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="WEii Bouwbesluit"
                        :items="weiiBldDecrees"
                        item-text="name"
                        item-value="key"
                        v-model="selectedData.weiiBldDecreeKey"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                        :error-messages="errors"
                      ></v-select>
                  </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="WEii Klasse" rules="required" v-slot="{ errors }">
                      <v-select
                        hide-details="auto"
                        persistent-placeholder
                        label="WEii Klasse"
                        :items="weiiBldCategories"
                        item-text="name"
                        item-value="key"
                        v-model="selectedData.weiiBldCatKey"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                        :error-messages="errors"
                      ></v-select>
                  </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      persistent-placeholder
                      label="NTA8800 functie"
                      :items="nta8800Functions"
                      item-text="descr"
                      item-value="id"
                      v-model="selectedData.nta8800FuncId"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select
                      hide-details="auto"
                      persistent-placeholder
                      label="EED"
                      :items="eedTypes"
                      item-text="description"
                      item-value="key"
                      v-model="selectedData.eedTyp"
                      :outlined="allowEdit"
                      :class="{ noBorders: !allowEdit }"
                      :readonly="!allowEdit"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="GBS" rules="max:50" v-slot="{ errors, valid }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        label="GBS"
                        :counter="50"
                        v-model="selectedData.gbsTyp"
                        :error-messages="errors"
                        :success="valid"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Hernieuwbare energie</header>
                <v-row>
                  <v-col>
                    <v-switch v-model="selectedData.isRenewEnergyUsed" label="Gebruik van hernieuwbare energie?" :readonly="isReadOnly" hide-details></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aandeel elektriciteit" rules="double|decimal:2|between:0,100.00" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Aandeel elektriciteit"
                        :value="selectedData.shareOfRenewElecPerc"
                        @input="selectedData.shareOfRenewElecPerc = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aandeel energie" rules="double|decimal:2|between:0,100.00" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Aandeel energie"
                        :value="selectedData.shareOfRenewEnergyPerc"
                        @input="selectedData.shareOfRenewEnergyPerc = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Aandeel warmte" rules="double|decimal:2|between:0,100.00" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Aandeel warmte"
                        :value="selectedData.shareOfRenewHeatPerc"
                        @input="selectedData.shareOfRenewHeatPerc = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">%</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Oppervlakten</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Gebruiksoppervlakte" rules="numeric|between:0,9999999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Gebruiksoppervlakte (GO)"
                        :value="selectedData.go"
                        @input="selectedData.go = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Bruto vloeroppervlakte" rules="numeric|between:0,9999999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Bruto vloeroppervlakte (BVO)"
                        :value="selectedData.bvo"
                        @input="selectedData.bvo = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Netto vloeroppervlakte" rules="numeric|between:0,9999999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Netto vloeroppervlakte (NVO)"
                        :value="selectedData.nvo"
                        @input="selectedData.nvo = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Verhuurbaar vloeroppervlakte" rules="numeric|between:0,9999999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Verhuurbaar vloeroppervlakte (VVO)"
                        :value="selectedData.vvo"
                        @input="selectedData.vvo = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Dakoppervlakte" rules="numeric|between:0,9999999" v-slot="{ errors }">
                      <v-text-field
                        type="number"
                        hide-details="auto"
                        persistent-placeholder
                        label="Dakoppervlakte (DO)"
                        :value="selectedData.do"
                        @input="selectedData.do = $event !== '' ? $event : undefined"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      >
                        <template slot="append">m²</template>
                      </v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="boxed">
                <header>Notities</header>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Notities" rules="max:1000" v-slot="{ errors }">
                      <v-text-field
                        hide-details="auto"
                        persistent-placeholder
                        v-model="selectedData.note"
                        :counter="1000"
                        :error-messages="errors"
                        :outlined="allowEdit"
                        :class="{ noBorders: !allowEdit }"
                        :readonly="!allowEdit"
                      ></v-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-container>
            </masonry>
            <details-buttons
              :addMode="isAddingMode"
              :editMode="!isReadOnly"
              :allowSave="!isSaving"
              @edit="setEditMode"
              @edit-tl="setEditTLMode"
              @on-cancel="onCancel"
              @add-new-detail="addNewDetail"
              @on-cancel-add="onCancelAdd"
              @save-detail="saveDetail"
              @save-new-detail="saveNewDetail"
            />
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
